import React, { useState, useMemo } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { access } from "fs";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

export const ListUserInvitations: React.FC = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: invitations = [] } = useQuery(
    [queries.invitations, input.tab, user.org_id],
    () => getRequest(`/users/org?org_id=${user.org_id}`),
    {
      enabled: !!input.tab || !!user.org_id,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: <span className="text-white">Name</span>,
        accessor: "name",
      },
      {
        Header: <span className="text-white">Email</span>,
        accessor: "email",
      },
      {
        Header: <span className="text-white">Phone</span>,
        accessor: "phone",
      },
      {
        id: "role",
        Header: <span className="text-white">Role</span>,
        accessor: "access_level",
        Cell: ({ value }: { value: number }) => {
          const roles = new Map<number, string>([
            [-1, "Disabled"],
            [0, "Agent"],
            [1, "Admin"],
          ]);
          return roles.get(value) || "Unknown";
        },
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/invitations/update/${data.id}`, {
      state: {
        name: data.name,
      },
    });
  };

  return (
    <div className="w-full h-full p-10 text-perl">
      <List
        columns={columns}
        data={invitations}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black">Team Members</h1>
          <div className="flex text-disabled justify-between items-center">
            <ToggleGroup
              innerClass="w-full text-disabled flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input["tab"]}
              onValueChange={(value: number) =>
                setInput({ ...input, ["tab"]: value })
              }
            />
            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/invitations/create")}
                className="bg-green text-white px-3 py-1 rounded-full"
              >
                Create
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
