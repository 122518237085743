import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 0,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

export const LocationList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: locations = [] } = useQuery(
    [queries.locations, input.tab, user.org_id],
    () => getRequest(`/locations/su`),

    {
      enabled: !!user.org_id || !!input.tab,
      cacheTime: 0, // Disable caching
      staleTime: 0, // Disable stale time
    }
  );

  // Log the response data to the console
  useEffect(() => {
    console.log("Location data:", locations);
  }, [locations]);

  const columns = useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "org_name",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }: { row: any }) => (
          <div>
            <div>{row.original.name}</div>
            <div className="text-sm text-gray-500">
              {format(
                new Date(row.original.created_at),
                "MMM dd, yyyy hh:mm a"
              )}
            </div>
          </div>
        ),
      },
      {
        Header: () => <span className="text-white">Contact Number</span>,
        accessor: "phone",
      },
      {
        Header: () => <span className="text-white">Address</span>,
        accessor: "address",
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/locations/update/${data.id}`, {
      state: {
        name: data.agent_name,
      },
    });
  };

  return (
    <div className="w-full h-full p-4 sm:p-6 lg:p-10 bg-primary text-white">
      <List
        columns={columns}
        data={locations}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-4 sm:space-y-6">
          <h1 className="text-xl sm:text-2xl font-black">Location</h1>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <ToggleGroup
              innerClass="w-full sm:w-auto text-disabled flex space-x-2 sm:space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input["tab"]}
              onValueChange={(value: number) =>
                setInput({ ...input, ["tab"]: value })
              }
            />

            {/* {user.access_level > 0 && (
              <button
                onClick={() => navigate("/locations/create")}
                className="bg-accent text-primary font-semibold px-4 sm:px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Create
              </button>
            )} */}
          </div>
        </div>
      </List>
    </div>
  );
};
