export const queries = {
  projects: "projects",
  dubbing: "dubbing",
  languages: "languages",
  translatedSegments: "translatedSegments",
  masterVideoUrl: "masterVideoUrl",
  mediaUrl: "mediaUrl",
  voices: "voices",
  favourites: "favourites",
  sourceVideoUrl: "sourceVideoUrl",
  call: "call",
  agents: "agents",
  transcriptions: "transcriptions",
  homestats: "homestats",
  subscriptions: "subscriptions",
  employeelist: "employeelist",
  summaries: "summaries",
  actionItems: "actionItems",
  phones: "phones",
  optionsList: "optionsList",
  knowledges: "knowledges",
  locations: "locations",
  calendars: "calendars",
  time_zones: "time_zones",
  resetPassword: "resetPassword",
  user: "user",
  invitations: "invitations",
  orgs: "orgs",
  users: "users",
  integrations: "integrations",
  organizations: "organizations",
  agentTemplates: "agentTemplates",
};
