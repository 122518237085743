import React, { useState } from "react";
import { TextInput, ScrollArea } from "../../components";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  putRequest,
  optimisticOptions,
  queries,
} from "../../react-query";
import { useNavigate, useParams } from "react-router-dom";

type AddressType = {
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip_code: string;
};

type ContactType = {
  full_name: string;
  position: string;
  phone: string;
  email: string;
};

type OrganizationType = {
  name: string;
  primary_contact: ContactType;
  business_address: AddressType;
  billing_address: AddressType & { same_as_business: boolean };
  billing_contact: ContactType;
  tax_number: string;
};

export const UpdateOrganization = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  // Initial state
  const [formData, setFormData] = useState<OrganizationType>({
    name: "",
    primary_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    business_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
    },
    billing_address: {
      address_line1: "",
      address_line2: "",
      city: "",
      state: "",
      zip_code: "",
      same_as_business: true,
    },
    billing_contact: {
      full_name: "",
      position: "",
      phone: "",
      email: "",
    },
    tax_number: "",
  });

  const [errors, setErrors] = useState<Partial<OrganizationType>>({});

  // Mutation for updating organization
  const { mutate: updateOrganization } = useMutation(
    putRequest,
    optimisticOptions(queries.organizations)
  );

  // Fetch organization data
  const {
    data: organization,
    isError,
    error,
  } = useQuery(
    [queries.organizations, id],
    () => getRequest(`/organisations/?organisation_id=${id}`),
    {
      enabled: !!id,
      onError: (err) => {
        console.error("Fetch error:", err);
      },
      onSuccess: (data) => {
        // Ensure data is in the expected format before setting
        if (data) {
          setFormData({
            name: data.name || "",
            primary_contact: data.primary_contact || {
              full_name: "",
              position: "",
              phone: "",
              email: "",
            },
            business_address: data.business_address || {
              address_line1: "",
              address_line2: "",
              city: "",
              state: "",
              zip_code: "",
            },
            billing_address: data.billing_address || {
              address_line1: "",
              address_line2: "",
              city: "",
              state: "",
              zip_code: "",
              same_as_business: true,
            },
            billing_contact: data.billing_contact || {
              full_name: "",
              position: "",
              phone: "",
              email: "",
            },
            tax_number: data.tax_number || "",
          });
        }
      },
    }
  );

  // Type guard to check if error is an instance of Error
  const isErrorObject = (error: unknown): error is Error =>
    error instanceof Error;

  // Handle form changes
  const onChangeHandler = (
    value: string,
    name: keyof OrganizationType,
    subName?: keyof AddressType | keyof ContactType
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: subName
        ? {
            ...(prevFormData[name] as AddressType | ContactType),
            [subName]: value,
          }
        : value,
    }));
  };

  // Handle form submission
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    updateOrganization({
      endPoint: `/organisations/?organisation_id=${id}`,
      payload: formData,
    });
    navigate(-1);
  };

  if (isError) {
    return (
      <div>
        Error:{" "}
        {isErrorObject(error) ? error.message : "An unknown error occurred"}
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-primary text-white">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex flex-col">
          <h2 className="text-xl font-semibold mb-2 text-white">
            {formData.name || "Update Organization"}
          </h2>
          <span className="text-perl text-base font-perl">
            Update Organization
          </span>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-6 px-4">
                {/* Organization Name */}
                <TextInput
                  type="text"
                  placeholder="Organization Name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  error={errors.name}
                >
                  <label htmlFor="name" className="text-lg font-semibold">
                    Organization Name
                  </label>
                </TextInput>

                {/* Primary Contact Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Primary Contact</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Full Name"
                      value={formData.primary_contact.full_name}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "primary_contact",
                          "full_name"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact?.full_name}
                    >
                      <label htmlFor="primary_contact_full_name">
                        Full Name
                      </label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Position"
                      value={formData.primary_contact.position}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "primary_contact",
                          "position"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact?.position}
                    >
                      <label htmlFor="primary_contact_position">Position</label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Phone"
                      value={formData.primary_contact.phone}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "primary_contact",
                          "phone"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact?.phone}
                    >
                      <label htmlFor="primary_contact_phone">Phone</label>
                    </TextInput>

                    <TextInput
                      type="email"
                      placeholder="Email"
                      value={formData.primary_contact.email}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "primary_contact",
                          "email"
                        )
                      }
                      theme="white"
                      error={errors.primary_contact?.email}
                    >
                      <label htmlFor="primary_contact_email">Email</label>
                    </TextInput>
                  </div>
                </div>

                {/* Business Address Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Business Address</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Address Line 1"
                      value={formData.business_address.address_line1}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "business_address",
                          "address_line1"
                        )
                      }
                      theme="white"
                      error={errors.business_address?.address_line1}
                    >
                      <label htmlFor="business_address_line1">
                        Address Line 1
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Address Line 2"
                      value={formData.business_address.address_line2}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "business_address",
                          "address_line2"
                        )
                      }
                      theme="white"
                      error={errors.business_address?.address_line2}
                    >
                      <label htmlFor="business_address_line2">
                        Address Line 2
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="City"
                      value={formData.business_address.city}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "business_address",
                          "city"
                        )
                      }
                      theme="white"
                      error={errors.business_address?.city}
                    >
                      <label htmlFor="business_city">City</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="State"
                      value={formData.business_address.state}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "business_address",
                          "state"
                        )
                      }
                      theme="white"
                      error={errors.business_address?.state}
                    >
                      <label htmlFor="business_state">State</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Zip Code"
                      value={formData.business_address.zip_code}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "business_address",
                          "zip_code"
                        )
                      }
                      theme="white"
                      error={errors.business_address?.zip_code}
                    >
                      <label htmlFor="business_zip_code">Zip Code</label>
                    </TextInput>
                  </div>
                </div>

                {/* Billing Address Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Billing Address</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Address Line 1"
                      value={formData.billing_address.address_line1}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_address",
                          "address_line1"
                        )
                      }
                      theme="white"
                      error={errors.billing_address?.address_line1}
                    >
                      <label htmlFor="billing_address_line1">
                        Address Line 1
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Address Line 2"
                      value={formData.billing_address.address_line2}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_address",
                          "address_line2"
                        )
                      }
                      theme="white"
                      error={errors.billing_address?.address_line2}
                    >
                      <label htmlFor="billing_address_line2">
                        Address Line 2
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="City"
                      value={formData.billing_address.city}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_address",
                          "city"
                        )
                      }
                      theme="white"
                      error={errors.billing_address?.city}
                    >
                      <label htmlFor="billing_city">City</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="State"
                      value={formData.billing_address.state}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_address",
                          "state"
                        )
                      }
                      theme="white"
                      error={errors.billing_address?.state}
                    >
                      <label htmlFor="billing_state">State</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Zip Code"
                      value={formData.billing_address.zip_code}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_address",
                          "zip_code"
                        )
                      }
                      theme="white"
                      error={errors.billing_address?.zip_code}
                    >
                      <label htmlFor="billing_zip_code">Zip Code</label>
                    </TextInput>
                  </div>
                </div>

                {/* Billing Contact Section */}
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold">Billing Contact</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <TextInput
                      type="text"
                      placeholder="Full Name"
                      value={formData.billing_contact.full_name}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_contact",
                          "full_name"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact?.full_name}
                    >
                      <label htmlFor="billing_contact_full_name">
                        Full Name
                      </label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Position"
                      value={formData.billing_contact.position}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_contact",
                          "position"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact?.position}
                    >
                      <label htmlFor="billing_contact_position">Position</label>
                    </TextInput>
                    <TextInput
                      type="text"
                      placeholder="Phone"
                      value={formData.billing_contact.phone}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_contact",
                          "phone"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact?.phone}
                    >
                      <label htmlFor="billing_contact_phone">Phone</label>
                    </TextInput>
                    <TextInput
                      type="email"
                      placeholder="Email"
                      value={formData.billing_contact.email}
                      onChange={(e) =>
                        onChangeHandler(
                          e.target.value,
                          "billing_contact",
                          "email"
                        )
                      }
                      theme="white"
                      error={errors.billing_contact?.email}
                    >
                      <label htmlFor="billing_contact_email">Email</label>
                    </TextInput>
                  </div>
                </div>

                {/* Tax Number */}
                <TextInput
                  type="text"
                  placeholder="Tax Number"
                  value={formData.tax_number}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "tax_number")
                  }
                  theme="white"
                  error={errors.tax_number}
                >
                  <label htmlFor="tax_number">Tax Number</label>
                </TextInput>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
