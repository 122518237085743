import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { uppercaseSpacedToCapitalized } from "../../utils";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

export const PhoneList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: phones = [] } = useQuery(
    [queries.phones, input.tab, user.org_id],
    () =>
      getRequest(
        `/phone-numbers/org?org_id=${user.org_id}&status=${
          input.tab === 2 ? 0 : input.tab
        }`
      ),
    {
      enabled: !!user.org_id || !!input.tab,
    }
  );

  // Fetch organizations data
  const { data: organizations = [] } = useQuery(
    [queries.organizations],
    () => getRequest("/organisations/list"),
    {
      enabled: true,
    }
  );

  // Create a map of org_id to organization name for efficient lookup
  const orgMap = useMemo(() => {
    return organizations.reduce((acc: { [key: string]: string }, org: any) => {
      acc[org.id] = org.name;
      return acc;
    }, {});
  }, [organizations]);

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Organization</span>,
        accessor: "org_id",
        Cell: ({ value }: { value: string }) => (
          <span>{orgMap[value] || "N/A"}</span>
        ),
      },
      {
        Header: () => <span className="text-white">Phone Number</span>,
        accessor: "phone_number",
        Cell: ({ row }: { row: any }) => (
          <div>
            <div>{row.original.phone_number}</div>
            <div className="text-sm text-gray-500">
              {format(
                new Date(row.original.created_at),
                "MMM dd, yyyy hh:mm a"
              )}
            </div>
          </div>
        ),
      },
      {
        Header: () => <span className="text-white">Attended by</span>,
        accessor: "inbound_agent.name",
      },
      {
        Header: () => <span className="text-white">Provider</span>,
        accessor: "provider",
        Cell: ({ value }: { value: string }) =>
          uppercaseSpacedToCapitalized(value),
      },
    ],
    [orgMap]
  );

  const handleRowClick = (data: any) => {
    navigate(`/phones/update/${data.id}`, {
      state: {
        name: data.agent_name,
      },
    });
  };

  return (
    <div className="w-full h-full p-4 sm:p-6 lg:p-10 bg-primary text-white">
      <List
        columns={columns}
        data={phones}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-4 sm:space-y-6">
          <h1 className="text-xl sm:text-2xl font-black">Phones</h1>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <ToggleGroup
              innerClass="w-full sm:w-auto text-disabled flex space-x-2 sm:space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input["tab"]}
              value={input["tab"]}
              onValueChange={(value: number) => {
                setInput({ ...input, ["tab"]: value });
              }}
            />

            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/phones/create")}
                className="bg-accent text-primary font-semibold px-4 sm:px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Create
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
