import React, { useEffect, useMemo, useState } from "react";
import { List, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  { id: "1", name: "Active", value: 1 },
  { id: "2", name: "Draft", value: 2 },
  { id: "3", name: "Deleted", value: -1 },
];

export const AgentsList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({ tab: 1 });

  const { data: agents = [] } = useQuery(
    [queries.agents, input.tab, user.org_id],
    () =>
      getRequest(
        `/agents/su?org_id=${user.org_id}&status=${
          input.tab === 2 ? 0 : input.tab
        }`
      ),
    {
      enabled: !!input.tab || !!user.org_id,
    }
  );

  // Log the response data to the console
  useEffect(() => {
    console.log("Agents data:", agents);
  }, [agents]);

  const columns = useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "org_name",
      },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }: { row: any }) => (
          <div>
            <div>{row.original.name}</div>
            <div className="text-sm text-gray-500">
              {format(
                new Date(row.original.created_at),
                "MMM dd, yyyy hh:mm a"
              )}
            </div>
          </div>
        ),
      },
      {
        Header: () => <span className="text-white">Voice</span>,
        accessor: "voice",
      },
      {
        Header: () => <span className="text-white">Role</span>,
        accessor: "role",
        Cell: ({ value }: any) => (
          <span className="truncate-text" title={value}>
            {value}
          </span>
        ),
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/agents/update`, {
      state: { data: data },
    });
  };

  return (
    <div className="w-full h-full p-4 sm:p-6 lg:p-10 bg-primary text-white">
      <List
        columns={columns}
        data={agents}
        activeTab={input.tab}
        onRowClick={handleRowClick}
      >
        <div className="space-y-4 sm:space-y-6">
          <h1 className="text-xl sm:text-2xl font-black">Virtual Employees</h1>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <ToggleGroup
              innerClass="w-full sm:w-auto text-disabled flex space-x-2 sm:space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input.tab}
              onValueChange={(value: number) =>
                setInput({ ...input, tab: value })
              }
            />
          </div>
        </div>
      </List>
    </div>
  );
};
