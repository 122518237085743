import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useStoreState } from "../../store/easy-peasy/hooks";

const providers = [
  {
    label: "Twilio",
    value: "twilio",
  },
];

export const UpdateKnowledge = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useStoreState((store) => store.user);

  const { data: knowledge_base = {} } = useQuery(
    [queries.knowledges, id],
    () => getRequest(`/knowledge_bases/?knowledge_base_id=${id}`),
    {
      enabled: !!id,
    }
  );

  // Fetch organization list
  const { data: organizations = [] } = useQuery(
    "organizations",
    () => getRequest(`/organisations/list`),
    {
      enabled: true,
    }
  );

  const [formData, setFormData] = useState<any>({
    title: "",
    purpose: "",
    body: "",
    status: true,
  });

  const [errors, setErrors] = useState({
    title: "",
    purpose: "",
    body: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateKnowledge } = useMutation(
    putRequest,
    optimisticOptions(queries.knowledges)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { title, body, purpose, status } = formData;
    updateKnowledge({
      endPoint: `/knowledge_bases/?knowledge_base_id=${id}`,
      payload: {
        title: title,
        body: body,
        purpose: purpose,
        status: status === true ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (knowledge_base) {
      const { title, body, purpose, status } = knowledge_base;
      setFormData({
        ...formData,
        title,
        body,
        purpose,
        status: status === 1 ? true : false,
      });
    }
  }, [knowledge_base]);

  // Find the organization name based on org_id from knowledge_base
  const organizationName = organizations.find(
    (org: { id: string }) => org.id === knowledge_base.org_id
  )?.name;

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex flex-col">
          {organizationName && (
            <h2 className="text-xl font-semibold mb-2 text-white">
              {organizationName} {/* Display organization name */}
            </h2>
          )}
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">
              Update Knowledge Base
            </span>
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="eg. San Jose - Service List"
                  value={formData.title}
                  onChange={(e) => onChangeHandler(e.target.value, "title")}
                  required
                  theme="white"
                  error={errors.title}
                >
                  <label htmlFor="title">Name</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="eg. Services available in San Jose location"
                  value={formData.purpose}
                  onChange={(e) => onChangeHandler(e.target.value, "purpose")}
                  required
                  theme="white"
                  error={errors.purpose}
                >
                  <label htmlFor="purpose">Purpose</label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[40vh] bg-transparent rounded-lg border border-header-border p-2"
                  placeholder="Enter the content in detail for virtual employee can understand"
                  value={formData.body}
                  onChange={(e) => onChangeHandler(e.target.value, "body")}
                  required
                  theme="white"
                  error={errors.body}
                >
                  <label htmlFor="body" className="pt-4">
                    Content
                  </label>
                </TextArea>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              {user.access_level > 0 && (
                <button
                  type="submit"
                  className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
