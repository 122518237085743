import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

export const CreateKnowledge = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [formData, setFormData] = useState<any>({
    title: "",
    purpose: "",
    body: "",
    status: true,
  });

  const [errors, setErrors] = useState({
    title: "",
    purpose: "",
    body: "",
    status: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createKnowledge } = useMutation(
    postRequest,
    optimisticOptions(queries.knowledges)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { title, body, purpose, status } = formData;
    createKnowledge({
      endPoint: "/knowledge_bases/su",
      payload: {
        title: title,
        body: body,
        purpose: purpose,
        status: status === true ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">
              New Knowledge Base
            </span>
          </div>
          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-310px)]">
              <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="eg. San Jose - Service List"
                  value={formData.title}
                  onChange={(e) => onChangeHandler(e.target.value, "title")}
                  required
                  theme="white"
                  error={errors.title}
                >
                  <label htmlFor="title">Name</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="eg. Services available in San Jose location"
                  value={formData.purpose}
                  onChange={(e) => onChangeHandler(e.target.value, "purpose")}
                  required
                  theme="white"
                  error={errors.purpose}
                >
                  <label htmlFor="purpose">Purpose</label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[40vh] bg-transparent rounded-lg border border-header-border p-2"
                  placeholder="Enter the content in detail for virtual employee can understand"
                  value={formData.body}
                  onChange={(e) => onChangeHandler(e.target.value, "body")}
                  required
                  theme="white"
                  error={errors.body}
                >
                  <label htmlFor="body" className="pt-4">
                    Content
                  </label>
                </TextArea>
              </div>
            </ScrollArea>
            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Create Knowledge Base
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
