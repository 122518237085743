import React, { useState, useMemo, useEffect } from "react";
import { List, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { format } from "date-fns";

const tabs = [
  { id: "1", name: "Active", value: 1 },
  { id: "2", name: "Inactive", value: 0 },
  { id: "3", name: "Archived", value: 2 },
];

export const OrganizationList: React.FC = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({ tab: 1 });

  const { data: organizations = [] } = useQuery(
    [queries.organizations, input.tab],
    () => getRequest(`/organisations/list?status=${input.tab}`), // Correct endpoint
    {
      enabled: !!input.tab,
      cacheTime: 0, // Disable caching
      staleTime: 0, // Disable stale time
    }
  );

  // Log the response data to the console
  useEffect(() => {
    console.log("Organization data:", organizations);
  }, [organizations]);

  const columns = useMemo(
    () => [
      {
        Header: "Organizations",
        accessor: "name",
        Cell: ({ row }: { row: any }) => (
          <div>
            <div>{row.original.name}</div>
            <div className="text-sm text-gray-500">
              {format(
                new Date(row.original.created_at),
                "MMM dd, yyyy hh:mm a"
              )}
            </div>
          </div>
        ),
      },
      {
        Header: "Address",
        accessor: "business_address",
        Cell: ({ value }: { value: any }) => {
          if (!value) return "No address provided";
          return (
            <div>
              {value.address_line1}
              {value.address_line2 && `, ${value.address_line2}`}
              {value.city && `, ${value.city}`}
              {value.state && `, ${value.state}`}
              {value.zip_code && ` ${value.zip_code}`}
            </div>
          );
        },
      },
      { Header: "Created", accessor: "created_at" },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/organizations/update/${data.id}`, {
      state: {
        name: data.name,
        address: data.business_address, // Pass address
        created: data.created_at, // Pass created date
      },
    });
  };

  return (
    <div className="w-full h-full p-10 bg-primary text-white">
      <List
        columns={columns}
        data={organizations}
        activeTab={input.tab}
        onRowClick={handleRowClick}
      >
        <div className="space-y-4">
          <h1 className="text-2xl font-bold">Organizations</h1>
          <div className="flex justify-between items-center">
            <ToggleGroup
              innerClass="w-full flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-perl data-[state=on]:border-b-2 data-[state=on]:border-perl"
              data={tabs}
              defaultValue={input.tab}
              onValueChange={(value: number) =>
                setInput({ ...input, tab: value })
              }
            />
            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/organizations/create")}
                className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
              >
                Create Organization
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
