import React, { useCallback, useEffect, useState } from "react";
import {
  CustomSelect,
  NormalSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";
import { TextArea } from "../../components/input/TextArea";
import {
  ArrowLeftIcon,
  GearIcon,
  CardStackIcon,
  TrashIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { ConfirmDialog, NormalDialog } from "../../components/dialog";
import { toast } from "react-toastify";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useStoreState } from "../../store/easy-peasy/hooks";

const languages = [
  {
    id: "1",
    name: "English - US",
    value: "en-US",
  },
  {
    id: "2",
    name: "English - GB",
    value: "en-GB",
  },
  // {
  //   id: "3",
  //   name: "English - NZ",
  //   value: "en-NZ",
  // },
  // {
  //   id: "4",
  //   name: "English - IN",
  //   value: "en-IN",
  // },
  // {
  //   id: "5",
  //   name: "Hindi",
  //   value: "hi",
  // },
  // {
  //   id: "6",
  //   name: "German",
  //   value: "de",
  // },
  // {
  //   id: "7",
  //   name: "Spanish",
  //   value: "es",
  // },
  // {
  //   id: "8",
  //   name: "Spanish - Latin",
  //   value: "es-LATAM",
  // },
];

export const UpdateAgent = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const user = useStoreState((store) => store.user);
  const [isTrashOpen, setIsTrashOpen] = useState<boolean>(false);
  const {
    state: { data: agent },
  } = useLocation();
  const navigate = useNavigate();

  const { data: optionsList = [] } = useQuery([queries.optionsList], () =>
    getRequest(`/agents/addon-options`)
  );

  const { data = {} } = useQuery(
    [queries.agents, agent.id],
    () => getRequest(`/agents/?agent_id=${agent.id}`),
    {
      enabled: !!agent.id,
    }
  );

  const { data: phoneNumberList = [] } = useQuery([queries.phones], () =>
    getRequest(`/phone-numbers/org?status=1`)
  );

  const [formData, setFormData] = useState<any>({
    name: "",
    role: "",
    prompt: "",
    msg: "",
    description: "",
    language: "en-GB",
    voice: "",
    testNumber: "",
    countryCode: "",
    tools: [],
    status: true,
    locations: [],
    calendars: [],
    knowledges: [],
    phoneNumber: "",
    phoneNumberId: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    role: "",
    prompt: "",
    msg: "",
    description: "",
    tools: "",
    status: "",
    testNumber: "",
    locations: "",
    calendars: "",
    knowledges: "",
  });

  function onChangeHandler(value: string | boolean, key: string) {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [key]: value,
    }));
  }

  const { mutate: updateAgent } = useMutation(
    putRequest,
    optimisticOptions(queries.agents)
  );

  const { mutate: deleteAgent } = useMutation(
    deleteRequest,
    optimisticOptions(queries.agents)
  );

  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        name: data.name,
        role: data.role,
        prompt: data?.prompt?.body,
        msg: data.welcome_msg,
        description: "",
        language: data.language,
        voice: data.tts?.voice_id,
        tools: [],
        testNumber: "",
        status: true,
        countryCode: "",
        phoneNumber: data?.outbound_number?.phone_number,
        phoneNumberId: data?.outbound_number?.id,
        locations: data?.locations?.map((location: any) => {
          return {
            label: location?.name,
            value: location?.id,
          };
        }),
        calendars: data?.calendars?.map((calendar: any) => {
          return {
            label: calendar?.title,
            value: calendar?.id,
          };
        }),
        knowledges: data?.knowledge_bases?.map((knowledge: any) => {
          return {
            label: knowledge?.title,
            value: knowledge?.id,
          };
        }),
      });
    }
  }, [data]);

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      const {
        name,
        role,
        voice,
        language,
        description,
        prompt,
        tools,
        msg,
        status,
      } = formData;

      const payload = {
        name: name,
        role: role,
        tts: {
          provider: "deepgram",
          voice_id: voice,
        },
        prompt: {
          body: prompt,
        },
        tools: tools,
        welcome_msg: msg,
        status: status === true ? 1 : 0,
        knowledge_base_ids: formData.knowledges?.map((ele: any) => ele.value),
        calendar_ids: formData.calendars?.map((ele: any) => ele.value),
        location_ids: formData.locations?.map((ele: any) => ele.value),
        outbound_number_id: formData.phoneNumberId,
      };

      //console.log("payload", payload);
      updateAgent({
        endPoint: `/agents/?agent_id=${data.id}`,
        payload: payload,
      });
      navigate("/agents");
    },
    [formData]
  );

  const onCallTest = (id: string) => {
    if (formData.testNumber !== "") {
      try {
        postRequest({
          endPoint: `/twilio/call`,
          payload: {
            to_number: `${formData.countryCode}${formData.testNumber}`,
            agent_id: id,
            phone_number_id: formData.phoneNumberId,
          },
        });
        toast.success("Your request is successfully submitted");
        setIsOpen(!isOpen);
      } catch (error) {}
    } else {
      setErrors({ ...errors, testNumber: "Required field!" });
    }
  };

  const onDeleteAgents = (id: string) => {
    deleteAgent({
      endPoint: `/agents/?agent_id=${id}`,
      payload: {},
    });
    toast.success("Your request is successfully submitted");
  };

  return (
    <>
      {formData && (
        <div className="w-full h-full p-4">
          <form onSubmit={submitHandler} className="h-full py-4 space-y-4">
            <div className="w-[60%] rounded-lg border items-center border-header-border bg-primary  py-4 px-4 flex justify-between space-x-4">
              <div className="space-y-2">
                <div className="w-full flex justify-start space-x-4 ">
                  <div className="flex space-x-3 items-center">
                    <img
                      className="w-8 h-8 rounded-full"
                      src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />

                    <NormalSelect
                      theme="white"
                      className="border-none text-nowrap"
                      data={optionsList.voices}
                      onValueChange={(value: string) =>
                        onChangeHandler(value, "voice")
                      }
                      defaultValue={formData.voice}
                      value={formData.voice}
                      placeholder="Voice"
                      keyField="id"
                      valueField="name"
                    ></NormalSelect>
                  </div>

                  <div className="flex space-x-3 items-center">
                    <p className="flex space-x-6 items-start text-perl text-nowrap">
                      Paired to{" "}
                    </p>
                    <NormalSelect
                      theme="white"
                      className="border-none text-nowrap"
                      data={phoneNumberList.map((ele: any) => ({
                        phoneNumberId: ele.phone_number,
                        phoneNumber: ele.id,
                      }))}
                      onValueChange={(value: string) => {
                        const selectedItem = phoneNumberList.find(
                          (ele: any) => ele.id === value
                        );
                        if (selectedItem) {
                          onChangeHandler(selectedItem.id, "phoneNumberId");
                          onChangeHandler(
                            selectedItem.phone_number,
                            "phoneNumber"
                          );
                        }
                      }}
                      defaultValue={formData.phoneNumberId}
                      value={formData.phoneNumberId}
                      placeholder="Phone Number"
                      keyField="phoneNumber"
                      valueField="phoneNumberId"
                    ></NormalSelect>
                  </div>

                  {/* <NormalSelect
                    theme="white"
                    className="border-none text-nowrap"
                    data={languages}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "language")
                    }
                    value={formData.language}
                    defaultValue={formData.language}
                    placeholder="Language"
                  ></NormalSelect> */}
                </div>
              </div>

              <div className="flex space-x-6 items-start text-white text-nowrap">
                <NormalSwitch
                  title="Status"
                  checked={formData.status}
                  onCheckedChange={(value: boolean) =>
                    onChangeHandler(value, "status")
                  }
                />

                {/* <button className="inline-flex space-x-2 h-8 items-center hover:text-logo">
              <GearIcon />
            </button>

            <button className="inline-flex space-x-2 h-8 items-center hover:text-logo">
              <CardStackIcon />
            </button> */}

                <button
                  onClick={() => setIsTrashOpen((prev) => !prev)}
                  className="inline-flex space-x-2 h-8 items-center hover:text-logo"
                >
                  <TrashIcon />
                </button>
              </div>
            </div>

            <div className="w-full flex justify-between space-x-4 text-white">
              <div className="w-[60%] rounded-lg  border  border-header-border bg-primary  p-4">
                <ScrollArea className="h-[calc(100vh-240px)] ">
                  <div className="space-y-2 ">
                    <TextInput
                      type="text"
                      placeholder="What's your assistant to be called?  (eg. Christof)"
                      value={formData.name}
                      onChange={(e) => onChangeHandler(e.target.value, "name")}
                      required
                      theme="white"
                      className=" bg-transparent rounded-lg  border border-header-border p-2"
                      error={errors.name}
                    >
                      <label htmlFor="Welcome" className="pt-4">
                        Assistant Name
                      </label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Front office assistant to help booking appointment for car service booking"
                      value={formData.role}
                      onChange={(e) => onChangeHandler(e.target.value, "role")}
                      required
                      theme="white"
                      className="bg-transparent rounded-lg  border border-header-border p-2"
                      error={errors.role}
                    >
                      <label htmlFor="Welcome" className="pt-4">
                        Assistant Role
                      </label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Welcome to Tring Motors, how can I assist you today?"
                      value={formData.msg}
                      onChange={(e) => onChangeHandler(e.target.value, "msg")}
                      required
                      theme="white"
                      className=" bg-transparent rounded-lg  border border-header-border p-2"
                      error={errors.msg}
                    >
                      <label htmlFor="Welcome" className="pt-4">
                        Welcome Message
                      </label>
                    </TextInput>

                    {user.access_level > 0 && (
                      <TextArea
                        className="focus:outline-none min-h-[44vh] bg-transparent rounded-lg  border border-header-border p-2"
                        placeholder="Enter instructions for your assistant"
                        value={formData.prompt}
                        onChange={(e) =>
                          onChangeHandler(e.target.value, "prompt")
                        }
                        required
                        theme="white"
                        error={errors.prompt}
                      >
                        <label htmlFor="prompt" className="pt-4">
                          Assistant Instructions
                        </label>
                      </TextArea>
                    )}

                    <div className="space-y-6 py-4">
                      <MultiSelect
                        theme="black"
                        keyField="id"
                        valueField="name"
                        className="bg-transparent rounded-lg  border border-header-border p-2"
                        data={optionsList.locations}
                        onValueChange={(value) =>
                          onChangeHandler(value, "locations")
                        }
                        value={formData.locations}
                        defaultValue={formData.locations}
                        placeholder="Select"
                      >
                        <label htmlFor="role">Locations</label>
                      </MultiSelect>

                      <MultiSelect
                        theme="black"
                        keyField="id"
                        valueField="title"
                        className="border border-main text-nowrap"
                        data={optionsList.knowledge_bases}
                        onValueChange={(value) =>
                          onChangeHandler(value, "knowledges")
                        }
                        value={formData.knowledges}
                        defaultValue={formData.knowledges}
                        placeholder="Select"
                      >
                        <label htmlFor="role">Knowledge Bases</label>
                      </MultiSelect>

                      <MultiSelect
                        theme="black"
                        keyField="id"
                        valueField="title"
                        className="border border-main text-nowrap"
                        data={optionsList.calendars}
                        onValueChange={(value) =>
                          onChangeHandler(value, "calendars")
                        }
                        value={formData.calendars}
                        defaultValue={formData.calendars}
                        placeholder="Select"
                      >
                        <label htmlFor="role">Calendars</label>
                      </MultiSelect>
                    </div>

                    <div className="flex justify-end">
                      {user.access_level > 0 && (
                        <button
                          type="submit"
                          className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </ScrollArea>
              </div>

              <div className="w-[35%] space-y-8">
                <div className="h-40 p-4 text-white border border-header-border  rounded-lg h-full">
                  <p className="font-bold">Test Assistant</p>
                  <button
                    type="button"
                    onClick={() => setIsOpen((prev) => !prev)}
                    className="inline-flex space-x-2 h-8 rounded-lg items-center hover:text-logo "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                    <span className="text-perl">Call and Test</span>
                  </button>
                </div>
              </div>
            </div>
          </form>

          <NormalDialog
            isOpen={isTrashOpen}
            onClose={() => setIsTrashOpen(!isTrashOpen)}
            onConfirm={() => {
              onDeleteAgents(data.id);
              setIsOpen(!isOpen);
            }}
          />

          <ConfirmDialog
            testNumber={formData.testNumber}
            onChangeHandler={onChangeHandler}
            error={errors.testNumber}
            isOpen={isOpen}
            onClose={() => setIsOpen(!isOpen)}
            onConfirm={() => {
              onCallTest(data.id);
            }}
          />
        </div>
      )}
    </>
  );
};
