import { useState } from "react";
import { Outlet } from "react-router-dom";
import { MainHeader, Sidebar } from "../components";

export const MainLayout = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  };

  return (
    <div className="w-screen h-screen bg-primary">
      <MainHeader onToggle={toggleSideMenu} />
      <div className="flex h-[90vh] bg-primary">
        {" "}
        <Sidebar isOpen={isSideMenuOpen} onClose={closeSideMenu} />
        <div className={`w-full bg-primary h-[90vh] overflow-y-scroll`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
