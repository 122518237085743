import React, { useState } from "react";
import { NormalSelect, TextInput, ScrollArea } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRequest, postRequest, queries } from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

interface FormData {
  name: string;
  description: string;
  prompt: string;
  tasks: string[];
  orgId: string;
  category: string;
  channel: string;
  status: string;
  imagePath?: string;
}

interface Errors {
  name: string;
  description: string;
  prompt: string;
  tasks: string;
  orgId: string;
  category: string;
  channel: string;
  status: string;
}

interface Organization {
  id: string;
  name: string;
}

interface AgentTemplatePayload {
  name: string;
  description: string;
  prompt: string;
  metadata: Record<string, any>;
  category: string;
  channel: string;
  image_path: string;
  status: string;
  tasks: string[];
  org_id: string | null;
}

export const CreateAgentTemplate: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useStoreState((store: any) => store.user);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    description: "",
    prompt: "",
    tasks: [],
    orgId: "",
    category: "",
    channel: "",
    status: "active",
    imagePath: "",
  });

  const [errors, setErrors] = useState<Errors>({
    name: "",
    description: "",
    prompt: "",
    tasks: "",
    orgId: "",
    category: "",
    channel: "",
    status: "",
  });

  // Fetch organizations
  const { data: organizations = [], isLoading: orgLoading } = useQuery<
    Organization[]
  >([queries.organizations], () => getRequest("/organisations/list"), {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  // Manually added tasks
  const taskOptions = ["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"];

  function onChangeHandler(value: any, key: keyof FormData) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  }

  const { mutate: createAgentTemplate, isLoading: isSubmitting } = useMutation(
    (payload: { endPoint: string; payload: AgentTemplatePayload }) =>
      postRequest({ endPoint: payload.endPoint, payload: payload.payload }),
    {
      onSuccess: (data) => {
        console.log("Agent template created successfully:", data);
        console.log("Invalidating query with params:", {
          org_id: formData.orgId,
          status: "active",
        });
        queryClient.invalidateQueries([
          queries.agentTemplates,
          formData.orgId,
          "active",
        ]);
        queryClient.refetchQueries([
          queries.agentTemplates,
          formData.orgId,
          "active",
        ]);
        setTimeout(() => {
          navigate("/agent-templates");
        }, 100);
        // Reset form data
        setFormData({
          name: "",
          description: "",
          prompt: "",
          tasks: [],
          orgId: "",
          category: "",
          channel: "",
          status: "active",
          imagePath: "",
        });
      },

      onError: (error: any) => {
        console.error("Failed to create agent template:", error);
        if (error.response && error.response.data) {
          // Display specific error messages based on API response
          setErrors({
            ...errors,
            name: error.response.data.name || errors.name,
            description: error.response.data.description || errors.description,
            prompt: error.response.data.prompt || errors.prompt,
            tasks: error.response.data.tasks || errors.tasks,
            orgId: error.response.data.org_id || errors.orgId,
            category: error.response.data.category || errors.category,
            channel: error.response.data.channel || errors.channel,
            status: error.response.data.status || errors.status,
          });
        } else {
          // Fallback for other types of errors
          setErrors({
            ...errors,
            name: "An unknown error occurred. Please try again.",
          });
        }
      },
    }
  );

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      name,
      description,
      prompt,
      orgId,
      category,
      channel,
      status,
      imagePath,
    } = formData;

    // Validate form data
    if (!name || !description || !prompt || !category || !channel || !status) {
      setErrors({
        name: !name ? "Name is required" : "",
        description: !description ? "Description is required" : "",
        prompt: !prompt ? "Prompt is required" : "",
        tasks: "",
        orgId: "", // Remove orgId validation
        category: !category ? "Category is required" : "",
        channel: !channel ? "Channel is required" : "",
        status: !status ? "Status is required" : "",
      });
      return;
    }

    const payload: AgentTemplatePayload = {
      name,
      description,
      prompt,
      metadata: {},
      category,
      channel,
      image_path: imagePath || "",
      status: status || "active",
      tasks: formData.tasks,
      org_id: orgId === "" ? null : orgId, // Change this line
    };

    console.log("Submitting agent template with payload:", payload);
    createAgentTemplate({
      endPoint: "/v2/agent_templates/",
      payload,
    });
  };

  return (
    <div className="w-full h-screen bg-primary text-white">
      <form onSubmit={submitHandler} className="h-full py-4 space-y-4">
        <div className="w-[60%] rounded-lg border items-center border-header-border bg-primary py-4 px-4 flex justify-between space-x-4">
          <div className="space-y-2">
            <div className="w-full flex justify-start space-x-4">
              <div className="flex space-x-3 items-center">
                {orgLoading ? (
                  <div>Loading...</div>
                ) : (
                  <NormalSelect
                    theme="white"
                    className="border-none text-nowrap"
                    data={[
                      {
                        id: "no_org",
                        name: "No Organization",
                        value: "no_org",
                      },
                      ...organizations.map((org) => ({
                        id: org.id,
                        name: org.name,
                        value: org.id,
                      })),
                    ]}
                    onValueChange={(value: string) =>
                      onChangeHandler(value === "no_org" ? "" : value, "orgId")
                    }
                    value={formData.orgId || "no_org"}
                    defaultValue="no_org"
                    placeholder="Select Organization (Optional)"
                    keyField="id"
                    valueField="name"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-240px)]">
              <div className="space-y-2">
                <TextInput
                  type="text"
                  placeholder="Agent Name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.name}
                >
                  <label htmlFor="name" className="pt-4">
                    Name
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Description of the agent"
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.description}
                >
                  <label htmlFor="description" className="pt-4">
                    Description
                  </label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[44vh] bg-transparent rounded-lg border border-header-border p-2"
                  placeholder="Instructions for your agent"
                  value={formData.prompt}
                  onChange={(e) => onChangeHandler(e.target.value, "prompt")}
                  required
                  theme="white"
                  error={errors.prompt}
                >
                  <label htmlFor="prompt" className="pt-4">
                    Prompt
                  </label>
                </TextArea>

                <MultiSelect
                  theme="black"
                  keyField="id"
                  valueField="name"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  data={taskOptions.map((task, index) => ({
                    id: index.toString(),
                    name: task,
                  }))}
                  onValueChange={(value: string[]) =>
                    onChangeHandler(value, "tasks")
                  }
                  value={formData.tasks}
                  defaultValue={[]}
                  placeholder="Select Tasks"
                >
                  <label htmlFor="tasks">Tasks (Optional)</label>
                </MultiSelect>

                <TextInput
                  type="text"
                  placeholder="Category"
                  value={formData.category}
                  onChange={(e) => onChangeHandler(e.target.value, "category")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.category}
                >
                  <label htmlFor="category" className="pt-4">
                    Category
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Channel"
                  value={formData.channel}
                  onChange={(e) => onChangeHandler(e.target.value, "channel")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.channel}
                >
                  <label htmlFor="channel" className="pt-4">
                    Channel
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Status"
                  value={formData.status}
                  onChange={(e) => onChangeHandler(e.target.value, "status")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.status}
                >
                  <label htmlFor="status" className="pt-4">
                    Status
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Image Path (Optional)"
                  value={formData.imagePath}
                  onChange={(e) => onChangeHandler(e.target.value, "imagePath")}
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="imagePath" className="pt-4">
                    Image Path
                  </label>
                </TextInput>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Creating..." : "Create"}
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </form>
    </div>
  );
};
