import React, { useState, useEffect } from "react";
import { NormalSelect, TextInput, ScrollArea } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getRequest, putRequest, queries } from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

interface FormData {
  name: string;
  description: string;
  prompt: string;
  tasks: string[];
  orgId: string;
  category: string;
  channel: string;
  status: string;
  imagePath: string;
}

interface Errors {
  name: string;
  description: string;
  prompt: string;
  tasks: string;
  orgId: string;
  category: string;
  channel: string;
  status: string;
}

interface Organization {
  id: string;
  name: string;
}

interface AgentTemplate {
  id: string;
  name: string;
  description: string;
  prompt: string;
  tasks: string[];
  org_id: string | null;
  category: string;
  channel: string;
  status: string;
  image_path: string;
  created_at: string;
  updated_at: string;
  version: number;
  metadata: any | null;
}

const fetchAgentTemplate = async (id: string | undefined) => {
  if (!id) throw new Error("No template ID provided");
  console.log(`Fetching agent template with ID: ${id}`);
  const response = await getRequest(
    `/v2/agent_templates/?agent_template_id=${id}`
  );
  console.log("Raw API Response:", response);
  console.log("Fetched prompt:", response.prompt);
  if (Array.isArray(response)) {
    return response[0];
  } else if (typeof response === "object" && response !== null) {
    return response;
  }
  throw new Error("Invalid data received from API");
};

export const UpdateAgentTemplate: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const user = useStoreState((store: any) => store.user);

  const [formData, setFormData] = useState<FormData>({
    name: "",
    description: "",
    prompt: "",
    tasks: [],
    orgId: "",
    category: "",
    channel: "",
    status: "active",
    imagePath: "",
  });

  const [errors, setErrors] = useState<Errors>({
    name: "",
    description: "",
    prompt: "",
    tasks: "",
    orgId: "",
    category: "",
    channel: "",
    status: "",
  });

  const { data: organizations = [], isLoading: orgLoading } = useQuery<
    Organization[]
  >([queries.organizations], () => getRequest("/organisations/list"), {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {
    data: agentTemplate,
    isLoading: agentLoading,
    error: agentError,
  } = useQuery<AgentTemplate>(
    [queries.agentTemplates, id],
    () => fetchAgentTemplate(id),
    {
      enabled: !!id,
      onSuccess: (data) => {
        console.log("Full fetched data:", data);
        if (data && typeof data === "object" && "name" in data) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: data.name || "",
            description: data.description || "",
            prompt: data.prompt || "",
            tasks: Array.isArray(data.tasks) ? data.tasks : [],
            orgId: data.org_id || "",
            category: data.category || "",
            channel: data.channel || "",
            status: data.status || "active",
            imagePath: data.image_path || "",
          }));
          console.log("Prompt set to:", data.prompt); // Add this line for debugging
        } else {
          console.error("Invalid data received from API:", data);
        }
      },
    }
  );

  const taskOptions = ["Task 1", "Task 2", "Task 3", "Task 4", "Task 5"];

  function onChangeHandler(value: any, key: keyof FormData) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
    console.log(`Field ${key} updated:`, value); // Add this line for debugging
  }

  const { mutate: updateAgentTemplate, isLoading: isSubmitting } = useMutation(
    (payload: Partial<AgentTemplate>) =>
      putRequest({
        endPoint: `/v2/agent_templates/?agent_template_id=${id}`,
        payload,
      }),
    {
      onSuccess: () => {
        console.log("Agent template updated successfully");
        queryClient.invalidateQueries([
          queries.agentTemplates,
          formData.orgId,
          "active",
        ]);
        navigate("/agent-templates");
      },
      onError: (error: any) => {
        console.error("Failed to update agent template:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        if (error.response && error.response.data) {
          setErrors({
            ...errors,
            name: error.response.data.name || errors.name,
            description: error.response.data.description || errors.description,
            prompt: error.response.data.prompt || errors.prompt,
            tasks: error.response.data.tasks || errors.tasks,
            orgId: error.response.data.org_id || errors.orgId,
            category: error.response.data.category || errors.category,
            channel: error.response.data.channel || errors.channel,
            status: error.response.data.status || errors.status,
          });
        } else {
          setErrors({
            ...errors,
            name: "An unknown error occurred. Please try again.",
          });
        }
      },
    }
  );

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      name,
      description,
      prompt,
      orgId,
      category,
      channel,
      status,
      imagePath,
      tasks,
    } = formData;

    if (!name || !description || !prompt || !category || !channel || !status) {
      setErrors({
        name: !name ? "Name is required" : "",
        description: !description ? "Description is required" : "",
        prompt: !prompt ? "Prompt is required" : "",
        tasks: "",
        orgId: "",
        category: !category ? "Category is required" : "",
        channel: !channel ? "Channel is required" : "",
        status: !status ? "Status is required" : "",
      });
      return;
    }

    const payload = {
      name: formData.name,
      description: formData.description,
      prompt: formData.prompt,
      tasks: formData.tasks.length > 0 ? formData.tasks : [],
      org_id: formData.orgId || null,
      category: formData.category,
      channel: formData.channel,
      status: formData.status,
      image_path: formData.imagePath || "",
    };

    console.log("Update payload:", JSON.stringify(payload, null, 2));
    updateAgentTemplate(payload);
  };

  useEffect(() => {
    console.log("FormData updated:", formData);
  }, [formData]);

  useEffect(() => {
    console.log("Prompt updated:", formData.prompt);
  }, [formData.prompt]);

  if (agentLoading) {
    return <div>Loading agent template...</div>;
  }

  if (agentError) {
    return (
      <div>
        Error loading agent template:{" "}
        {agentError instanceof Error ? agentError.message : "Unknown error"}
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-primary text-white">
      <form onSubmit={submitHandler} className="h-full py-4 space-y-4">
        <div className="w-[60%] rounded-lg border items-center border-header-border bg-primary py-4 px-4 flex justify-between space-x-4">
          <div className="space-y-2">
            <div className="w-full flex justify-start space-x-4">
              <div className="flex space-x-3 items-center">
                {orgLoading ? (
                  <div>Loading...</div>
                ) : (
                  <NormalSelect
                    theme="white"
                    className="border-none text-nowrap"
                    data={[
                      {
                        id: "no_org",
                        name: "No Organization",
                        value: "no_org",
                      },
                      ...organizations.map((org) => ({
                        id: org.id,
                        name: org.name,
                        value: org.id,
                      })),
                    ]}
                    onValueChange={(value: string) =>
                      onChangeHandler(value === "no_org" ? "" : value, "orgId")
                    }
                    value={formData.orgId || "no_org"}
                    defaultValue="no_org"
                    placeholder="Select Organization (Optional)"
                    keyField="id"
                    valueField="name"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-240px)]">
              <div className="space-y-2">
                <TextInput
                  type="text"
                  placeholder="Agent Name"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.name}
                >
                  <label htmlFor="name" className="pt-4">
                    Name
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Description of the agent"
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.description}
                >
                  <label htmlFor="description" className="pt-4">
                    Description
                  </label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[44vh] bg-transparent rounded-lg border border-header-border p-2"
                  placeholder="Instructions for your agent"
                  value={formData.prompt}
                  onChange={(e) => {
                    console.log("New prompt value:", e.target.value);
                    onChangeHandler(e.target.value, "prompt");
                  }}
                  required
                  theme="white"
                  error={errors.prompt}
                >
                  <label htmlFor="prompt" className="pt-4">
                    Prompt
                  </label>
                </TextArea>

                <MultiSelect
                  theme="black"
                  keyField="id"
                  valueField="name"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  data={taskOptions.map((task, index) => ({
                    id: index.toString(),
                    name: task,
                  }))}
                  onValueChange={(value: string[]) =>
                    onChangeHandler(value, "tasks")
                  }
                  value={formData.tasks}
                  defaultValue={[]}
                  placeholder="Select Tasks"
                >
                  <label htmlFor="tasks">Tasks (Optional)</label>
                </MultiSelect>

                <TextInput
                  type="text"
                  placeholder="Category"
                  value={formData.category}
                  onChange={(e) => onChangeHandler(e.target.value, "category")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.category}
                >
                  <label htmlFor="category" className="pt-4">
                    Category
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Channel"
                  value={formData.channel}
                  onChange={(e) => onChangeHandler(e.target.value, "channel")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.channel}
                >
                  <label htmlFor="channel" className="pt-4">
                    Channel
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Status"
                  value={formData.status}
                  onChange={(e) => onChangeHandler(e.target.value, "status")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                  error={errors.status}
                >
                  <label htmlFor="status" className="pt-4">
                    Status
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Image Path (Optional)"
                  value={formData.imagePath}
                  onChange={(e) => onChangeHandler(e.target.value, "imagePath")}
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="imagePath" className="pt-4">
                    Image Path
                  </label>
                </TextInput>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-accent text-primary font-semibold px-6 py-2 rounded-full border-2 border-perl hover:bg-opacity-90 transition-colors duration-200 whitespace-nowrap"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving..." : "Update"}
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </form>
    </div>
  );
};
